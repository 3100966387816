<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col">
        <v-sheet
          rounded="lg"
          elevation="4"
          class="py-32 px-16 p-xl-32"
        >
          <h3 class="mb-32">Button</h3>
          <div class="row mb-32">
            <div class="col-auto">
              <v-button>
                Я кнопка
              </v-button>
            </div>

            <div class="col-auto">
              <v-button disabled>
                disabled
              </v-button>
            </div>
          </div>

          <h5 class="mb-16 text-color-text-secondary">Тип</h5>
          <div class="row align-items-center mb-32">
            <div class="col-auto">
              <v-button rounded="pill">
                pill
              </v-button>
            </div>

            <div class="col-auto">
              <v-button outlined>
                outlined
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                rounded="pill"
                icon
                color="success"
              >
                <v-icon-svg
                  style="left: 1px; top: 0; width: .75rem; height: .75rem;"
                  stroke="none"
                  fill="white"
                  view-box="0 0 9 12"
                  width="12"
                  height="12"
                >
                  <svg-icon360-play/>
                </v-icon-svg>
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                icon
              >
                <v-icon-svg
                  style="left: 1px; top: 0; width: .75rem; height: .75rem;"
                  stroke="none"
                  fill="white"
                  view-box="0 0 9 12"
                  width="12"
                  height="12"
                >
                  <svg-icon360-play/>
                </v-icon-svg>
              </v-button>
            </div>

            <div class="col-auto">
              <v-button color="danger" transparent>
                transparent
              </v-button>
            </div>

            <div class="col-auto">
              <v-button link>
                link
              </v-button>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">Цвет</h5>
          <div class="row mb-32">
            <div class="col-auto">
              <v-button color="secondary">
                secondary
              </v-button>
            </div>

            <div class="col-auto">
              <v-button color="success">
                success
              </v-button>
            </div>

            <div class="col-auto">
              <v-button color="warning">
                warning
              </v-button>
            </div>

            <div class="col-auto">
              <v-button color="danger">
                error
              </v-button>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">Размер</h5>
          <div class="row mb-32">
            <div class="col-auto">
              <v-button size="xs">
                extra small
              </v-button>
            </div>

            <div class="col-auto">
              <v-button size="sm">
                small
              </v-button>
            </div>

            <div class="col-auto">
              <v-button>
                default (m)
              </v-button>
            </div>

            <div class="col-auto">
              <v-button size="lg">
                large
              </v-button>
            </div>

            <div class="col-auto">
              <v-button size="xl">
                extra large
              </v-button>
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Radio</h3>
          <div class="row mb-32">
            <div class="col">
              <v-radio
                v-model="answer"
                :value="variants[0].code"
              >
                Default
              </v-radio>
            </div>

            <div class="col">
              <v-radio
                v-model="answer"
                :value="variants[1].code"
                error
              >
                Error
              </v-radio>
            </div>

            <div class="col">
              <v-radio
                v-model="answer"
                :value="variants[2].code"
                success
              >
                Success
              </v-radio>
            </div>

            <div class="col">
              <v-radio
                v-model="answer"
                :value="variants[3].code"
                disabled
              >
                Disabled
              </v-radio>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">В плашках</h5>
          <processing-input-radio-media :content="processingInputRadioDivider"/>

          <div class="row mb-32">
            <div class="col">
              <v-sheet
                class="mb-8"
                v-for="variant in variants"
                :key="variant.code"
                :value="answer === variant.code"
                rounded
                outlined
              >
                <v-radio
                  v-model="answer"
                  :value="variant.code"
                  class="p-8"
                >
                  {{ variant.text }}
                </v-radio>
              </v-sheet>
            </div>
          </div>

          <h5 class="mb-16 text-color-text-secondary">С картинками</h5>
          <div class="row">
            <div
              v-for="variant in variants"
              :key="variant.code"
              class="col-auto mb-8"
            >
              <v-sheet
                cover
                outlined
                rounded
                :value="answer === variant.code"
                @click="answer = variant.code"
                class="p-8"
              >
                <img
                  :src="require(`@/assets/images/verstka/diagram-1.png`)"
                  alt=""
                  class="d-block"
                >
              </v-sheet>
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Checkbox</h3>
          <processing-input-checkbox :content="processingInputRadioDivider"/>
          <processing-input-checkbox-media :content="processingInputRadioDivider"/>

          <div class="row mb-32">
            <div class="col">
              <v-checkbox
                v-model="checkAnswer"
                :value="variants[0].text"
              >
                Default
              </v-checkbox>
            </div>

            <div class="col">
              <v-checkbox
                v-model="checkAnswer"
                :value="variants[1].text"
                error
              >
                Error
              </v-checkbox>
            </div>

            <div class="col">
              <v-checkbox
                v-model="checkAnswer"
                :value="variants[2].text"
                success
              >
                Success
              </v-checkbox>
            </div>

            <div class="col">
              <v-checkbox
                v-model="checkAnswer"
                :value="variants[3].text"
                disabled
              >
                Disabled
              </v-checkbox>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">В плашках</h5>
          <div class="row mx-n4 mb-32">
            <div
              v-for="variant in variants"
              :key="variant.code"
              class="col-12 col-lg px-4 mb-8 mb-lg-0"
            >
              <v-sheet
                rounded
                outlined
                :value="checkAnswer.includes(variant.text)"
                @click.prevent="checkAnswer.includes(variant.text)
                ? checkAnswer.splice(checkAnswer.indexOf(variant.text), 1)
                : checkAnswer.push(variant.text)"
              >
                <div class="row align-items-center h-100 m-0">
                  <div class="col-auto">
                    <v-checkbox
                      v-model="checkAnswer"
                      :value="variant.text"
                    />
                  </div>

                  <v-divider vertical/>

                  <div class="col py-8">
                    <span v-html="variant.text"/>
                  </div>
                </div>
              </v-sheet>
            </div>
          </div>
          <div class="row mb-32">
            <div class="col">
              <v-sheet
                class="mb-8"
                v-for="variant in variants"
                :key="variant.code"
                :value="checkAnswer.includes(variant.text)"
                rounded
                outlined
              >
                <v-checkbox
                  :model-value="checkAnswer"
                  :value="variant.text"
                  @change="checkAnswer = $event"
                  class="p-8"
                >
                  {{ variant.text }}
                </v-checkbox>
              </v-sheet>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">С картинками</h5>
          <div class="row">
            <div
              v-for="variant in variants"
              :key="variant.text"
              class="col-auto"
            >
              <v-sheet
                cover
                outlined
                rounded
                :value="checkAnswer.includes(variant.text)"
                @click="checkAnswer.includes(variant.text)
                ? checkAnswer.splice(checkAnswer.indexOf(variant.text), 1)
                : checkAnswer.push(variant.text)"
                class="p-8"
              >
                <img
                  :src="require(`@/assets/images/verstka/diagram-1.png`)"
                  alt=""
                  class="d-block"
                >
              </v-sheet>
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Input</h3>
          <div
            v-for="input in inputs"
            :key="input.label"
            class="row mb-32"
          >
            <form class="col">
              <v-field
                :error="!!input.error"
              >
                <label>{{ input.label }}</label>
                <v-input
                  v-model="input.value"
                  :required="input.required"
                  :placeholder="input.placeholder"
                ></v-input>
                <span class="v-field__help">{{ input.help }}</span>
                <span class="v-field__error">{{ input.error }}</span>
              </v-field>
            </form>
          </div>
          <h5 class="mb-16 text-color-text-secondary">С autogrow</h5>
          <p>
            Lorem
            <v-field>
              <v-input
                v-model="inputAutogrow.value"
                :placeholder="inputAutogrow.placeholder"
                autogrow
              />
            </v-field>
            dolor sit amet, consectetur adipisicing elit.
          </p>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Select</h3>
          <div class="row mb-32">
            <div class="col-4">
              <v-select
                v-model="selectAnswer"
                :options="groups"
              />
            </div>

            <div class="col-4">
              <v-select
                v-model="selectAnswer"
                :options="groups"
                :filterable="false"
                :searchable="false"
                append-to-body
              >
                <template #footer>
                  <span class="text-size-12 text-color-text-secondary">
                    Без поиска
                  </span>
                </template>
              </v-select>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">Multiple</h5>
          <div class="row mb-32">
            <div class="col-4">
              <v-select
                v-model="selectAnswerMultiple"
                :options="groups"
                multiple
              />
            </div>

            <div class="col-4">
              <v-select
                v-model="selectAnswerMultiple"
                :filterable="false"
                :options="groups"
                :searchable="false"
                append-to-body
                multiple
              >
                <template #footer>
                  <span class="text-size-12 text-color-text-secondary">
                    Без поиска
                  </span>
                </template>
              </v-select>
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Textarea</h3>
          <div
            v-for="(textarea, i) in textareas"
            :key="i"
            class="row mb-32"
          >
            <div class="col">
              <v-field
                :error="!!textarea.error"
              >
                <label>{{ textarea.label }}</label>
                <v-textarea
                  v-model="textarea.value"
                  :required="textarea.required"
                  :placeholder="textarea.placeholder"
                  :autogrow="textarea.autogrow"
                ></v-textarea>
                <span class="v-field__help">{{ textarea.help }}</span>
                <span class="v-field__error">{{ textarea.error }}</span>
              </v-field>
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Table</h3>
          <h5 class="mb-16 text-color-text-secondary">Обычная</h5>
          <div class="row mb-32">
            <div class="col">
              <table-radio
                ref="radioTable"
                :variant-row="variantRow"
                :variant-col="variantCol"
              >
                <template slot="th-first">Утверждения</template>
              </table-radio>
            </div>
          </div>
          <h5 class="mb-16 text-color-text-secondary">Mostlist</h5>
          <div class="row mb-32">
            <div class="col">
              <table-radio
                ref="radioTable"
                :variant-row="variantRow"
                :variant-col="variantCol"
                unique
              >
                <template slot="th-first">4 колонки</template>
              </table-radio>
            </div>
          </div>
          <div class="row mb-32">
            <div class="col">
              <table-radio
                ref="radioTable3"
                :variant-row="variantRow3"
                :variant-col="variantCol3"
                unique
              >
                <template slot="th-first">3 колонки</template>
              </table-radio>
            </div>
          </div>
          <div class="row mb-32">
            <div class="col">
              <table-radio
                ref="radioTable2"
                :variant-row="variantRow2"
                :variant-col="variantCol2"
                unique
              >
                <template slot="th-first">2 колонки</template>
              </table-radio>
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">DnD</h3>
          <div class="row mt-40">
            <div class="col">
              <h5 class="mb-16 text-color-text-secondary">Ранжирование</h5>

              <drag-ranging :variants="variants"/>
            </div>
          </div>
          <div class="row mt-40">
            <div class="col">
              <h5 class="mb-16 text-color-text-secondary">По группам</h5>

              <drag-ranging-multiple
                :variants="variants"
                :groups="groups"
              ></drag-ranging-multiple>
            </div>
          </div>
          <div class="row mt-40">
            <div class="col">
              <h5 class="mb-16 text-color-text-secondary">Чебурашка</h5>
              <p class="text-size-12">Максимум 3 варианта</p>

              <drag-ranging-chebur
                :variants="variantChebur"
                :max="3"
              />
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Slider</h3>
          <div class="row align-items-center mb-32">
            <div class="col-6 col-md-auto pr-md-40">
              Ужасно!
            </div>

            <div class="col-6 col-md-auto order-md-3 pl-md-40 text-right">
              Все супер!
            </div>

            <div class="col-12 col-md">
              <v-slider
                v-model.number="variantSliderValue"
                :variants="variantSlider"
                with-text
              />
            </div>
          </div>
          <h5 class="mb-32 text-color-text-secondary">Цветной</h5>
          <div class="row align-items-center mb-32">
            <div class="col-6 col-md-auto pr-md-40">
              Ужасно!
            </div>
            <div class="col-6 col-md-auto order-md-3 pl-md-40 text-right">
              Все супер!
            </div>
            <div class="col-12 col-md">
              <v-slider
                with-label
                with-text
                v-model.number="variantSliderValue"
                :variants="variantSlider"
                :colors="[
                  '#D21212',
                  '#D21212',
                  '#F88843',
                  '#F8A543',
                  '#F8F143',
                  '#BFF943',
                  '#7DF843',
                  '#43F855',
                  '#39EA40',
                  '#33DE3B',
                  '#04B420',
                ]"
                :line-height="24"
              />
            </div>
          </div>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Snackbar</h3>
          <div class="row mb-32">
            <div class="col-auto">
              <v-button
                @click="(() => {
                  snackbar.color = null
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = null
                  snackbar.text = 'Не очень важное сообщение для пользователя'
                })"
              >
                Default
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                @click="(() => {
                  snackbar.color = null
                  snackbar.endless = true
                  snackbar.isShow = true
                  snackbar.position = null
                  snackbar.text = 'Пока не нажмёшь кнопку, не скроется'
                })"
              >
                Без таймера
              </v-button>
            </div>
          </div>

          <h5 class="mb-16 text-color-text-secondary">Позиция</h5>

          <div class="row mb-32">
            <div class="col-auto">
              <v-button
                @click="(() => {
                  snackbar.color = null
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = 'left'
                  snackbar.text = 'Слева'
                })"
              >
                Слева
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                @click="(() => {
                  snackbar.color = null
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = 'right'
                  snackbar.text = 'Справа'
                })"
              >
                Справа
              </v-button>
            </div>
          </div>

          <h5 class="mb-16 text-color-text-secondary">Цвет</h5>

          <div class="row mb-32">
            <div class="col-auto">
              <v-button
                color="secondary"
                @click="(() => {
                  snackbar.color = 'secondary'
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = null
                  snackbar.text = 'Второе сообщение для пользователя'
                })"
              >
                Secondary
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                color="success"
                @click="(() => {
                  snackbar.color = 'success'
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = null
                  snackbar.text = 'Всё прошло успешно'
                })"
              >
                Success
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                color="danger"
                @click="(() => {
                  snackbar.color = 'danger'
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = null
                  snackbar.text = 'Что-то пошло не так =('
                })"
              >
                Error
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                color="warning"
                @click="(() => {
                  snackbar.color = 'warning'
                  snackbar.endless = false
                  snackbar.isShow = true
                  snackbar.position = null
                  snackbar.text = 'Возможны осадки в виде фрикаделек'
                })"
              >
                Warning
              </v-button>
            </div>
          </div>

          <v-snackbar
            v-model="snackbar.isShow"
            :color="snackbar.color"
            :endless="snackbar.endless"
            :position="snackbar.position"
          >
            {{ snackbar.text }}

            <template #action v-if="snackbar.endless">
              <v-button
                outlined
                size="sm"
                @click="snackbar.isShow = false"
                class="ml-32"
              >
                Заркрыть
              </v-button>
            </template>
          </v-snackbar>

          <v-divider class="my-40"/>

          <h3 class="mb-32">Modal</h3>
          <div class="row mt-32">
            <div class="col">
              <v-button @click="showModal = true">Простое модальное окно</v-button>

              <v-modal v-model="showModal">
                <h4 slot="header">Внимание!</h4>

                <p>
                  Зафиксировано нажатие кнопки <b>Print Screen</b>. Данная информация будет передана организаторам
                  тестирования.
                  Пожалуйста, не пытайтесь сделать скриншоты вопросов теста.
                </p>

                <v-button
                  slot="footer"
                  @click="showModal = false"
                >
                  Вернуться к тексту
                </v-button>
              </v-modal>
            </div>
          </div>

          <h3 class="mb-32">Store</h3>
          <div class="row mt-32">
            <div class="col-auto">
              <v-button @click="showModalHandler">Модальное окно</v-button>
            </div>
            <div class="col-auto">
              <v-button @click="showSnackbarHandler('Привет')">Snackbar</v-button>
            </div>
            <div class="col-auto">
              <v-button @click="showSnackbarHandler('Пока', {color: 'danger'})">Snackbar</v-button>
            </div>
            <div class="col-auto"></div>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import {
  VButton,
  VCheckbox,
  VField,
  VIconSvg,
  VInput,
  VModal,
  VRadio,
  VSheet,
  VSlider,
  VSnackbar,
  VTextarea
} from '@components/base'
import {
  DragRanging,
  DragRangingChebur,
  DragRangingMultiple,
  TableRadio
} from '@components/features'
import SvgIcon360Play from '@components/icons/360/SvgIcon360Play'
import VDivider from '@components/base/VDivider/VDivider'
import ProcessingInputRadioMedia
  from '@views/Processing/ProcessingInput/ProcessingInputRadio/ProcessingInputRadioMedia'
import { mapActions } from 'vuex'
import ProcessingInputCheckbox from '@views/Processing/ProcessingInput/ProcessingInputCheckbox/ProcessingInputCheckbox'
import ProcessingInputCheckboxMedia
  from '@views/Processing/ProcessingInput/ProcessingInputCheckbox/ProcessingInputCheckboxMedia'

export default {
  name: 'SUi',

  provide () {
    return {
      sendData: this.sendData,
      sendDataAjax: this.sendDataAjax,
      service: this.service
    }
  },

  components: {
    ProcessingInputCheckboxMedia,
    ProcessingInputCheckbox,
    ProcessingInputRadioMedia,
    VDivider,
    VSheet,
    VSnackbar,
    DragRanging,
    DragRangingChebur,
    DragRangingMultiple,
    SvgIcon360Play,
    TableRadio,
    VButton,
    VCheckbox,
    VField,
    VIconSvg,
    VInput,
    VModal,
    VRadio,
    VSelect,
    VSlider,
    VTextarea
  },

  data: () => ({
    sendData: {},
    service: {
      validation: {
        state: {},
        mustPass: []
      }
    },
    cardButtonHeight: 0,
    isOversize: false,
    snackbar: {
      endless: false,
      color: null,
      isShow: false,
      position: 'center',
      text: 'Не очень важное сообщение для пользователя'
    },
    selectAnswer: '',
    selectAnswerMultiple: [],
    answer: '',
    checkAnswer: [],
    groups: ['Животные', 'Растения', 'Грибы', 'Вирусы'],
    radioCardMaxHeight: 10,
    showModal: false,
    variants: [
      { id: 1, media: 'a1-1.png', text: 'Человек' },
      { id: 2, media: 'a1-2.png', text: 'Одуванчик' },
      {
        id: 3,
        media: 'a1-1.png',
        text: 'Бабочка'
      },
      { id: 4, media: 'a1-3.png', text: ' Прикладная математика и Юриспруденция' },
      { id: 5, media: 'a1-4.png', text: 'Коронавирус' }
    ],
    variantRow: [
      {
        id: '3',
        text: ' Я берусь за выполнение сложных рабочих задач',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '4',
        text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '2',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad adipisci alias asperiores eaque eos itaque iure iusto laborum maxime, molestias, necessitatibus nostrum quis quisquam quos rerum sequi tempora, velit.',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '1',
        text: ' Я забочусь о чувствах других людей на своей работе',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      }
    ],
    variantCol: [
      {
        id: '1',
        text: '  Наименее подходит',
        media: '',
        group: '',
        sort: '1',
        attribute: '',
        isActive: false
      },
      {
        id: '2',
        text: '  Менее подходит',
        media: '',
        group: '',
        sort: '2',
        attribute: '',
        isActive: false
      },
      {
        id: '3',
        text: '  Более подходит',
        media: '',
        group: '',
        sort: '3',
        attribute: '',
        isActive: false
      },
      {
        id: '4',
        text: '  Наиболее подходит',
        media: '',
        group: '',
        sort: '4',
        attribute: '',
        isActive: false
      }
    ],
    variantRow2: [
      {
        id: '3',
        text: ' Я берусь за выполнение сложных рабочих задач',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '4',
        text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '2',
        text: ' Я берусь за задачи, в которых могу научиться новому',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '1',
        text: ' Я забочусь о чувствах других людей на своей работе',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      }
    ],
    variantCol2: [
      {
        id: '1',
        text: 'Подходит',
        media: '',
        group: '',
        sort: '1',
        attribute: '',
        isActive: false
      },
      {
        id: '2',
        text: 'Неподходит',
        media: '',
        group: '',
        sort: '2',
        attribute: '',
        isActive: false
      }
    ],
    variantRow3: [
      {
        id: '5',
        text: 'Какое-то значение',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '3',
        text: ' Я берусь за выполнение сложных рабочих задач',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '4',
        text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '2',
        text: ' Я берусь за задачи, в которых могу научиться новому',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      },
      {
        id: '1',
        text: ' Я забочусь о чувствах других людей на своей работе',
        media: '',
        group: '',
        sort: '99999',
        attribute: '',
        isActive: false
      }
    ],
    variantCol3: [
      {
        id: '1',
        text: 'Подходит',
        media: '',
        group: '',
        sort: '1',
        attribute: '',
        isActive: false
      },
      {
        id: '2',
        text: 'Неподходит',
        media: '',
        group: '',
        sort: '2',
        attribute: '',
        isActive: false
      },
      {
        id: '3',
        text: '3 значение',
        media: '',
        group: '',
        sort: '2',
        attribute: '',
        isActive: false
      }
    ],
    variantSliderValue: 0,
    variantSlider: [
      {
        attribute: '',
        group: '',
        id: '0',
        media: '',
        sort: '',
        label: 'Описание 0',
        text: '0'
      },
      {
        attribute: '',
        group: '',
        id: '1',
        media: '',
        sort: '',
        label: 'Описание 1',
        text: '1'
      },
      {
        attribute: '',
        group: '',
        id: '2',
        media: '',
        sort: '',
        label: 'Описание 2',
        text: '2'
      },
      {
        attribute: '',
        group: '',
        id: '3',
        media: '',
        sort: '',
        label: 'Описание 3',
        text: '3'
      },
      {
        attribute: '',
        group: '',
        id: '4',
        media: '',
        sort: '',
        label: 'Описание 4',
        text: '4'
      },
      {
        attribute: '',
        group: '',
        id: '5',
        media: '',
        sort: '',
        label: 'Описание 5',
        text: '5'
      },
      {
        attribute: '',
        group: '',
        id: '6',
        media: '',
        sort: '',
        label: 'Описание 6',
        text: '6'
      },
      {
        attribute: '',
        group: '',
        id: '7',
        media: '',
        sort: '',
        label: 'Описание 7',
        text: '7'
      },
      {
        attribute: '',
        group: '',
        id: '8',
        media: '',
        sort: '',
        label: 'Описание 8',
        text: '8'
      },
      {
        attribute: '',
        group: '',
        id: '9',
        media: '',
        sort: '',
        label: 'Описание 9',
        text: '9'
      },
      {
        attribute: '',
        group: '',
        id: '10',
        media: '',
        sort: '',
        label: 'Описание 10',
        text: '10'
      }
    ],
    textareas: [
      {
        value: '',
        help: 'c autogrow',
        autogrow: true,
        label: 'Введите ваш ответ',
        required: true
      },
      {
        value: '',
        placeholder: 'Введите ваше имя'
      }
    ],
    inputs: [
      {
        value: '',
        label: 'Фамилия',
        placeholder: 'Введите вашу фамилию',
        required: true,
        help: 'Какая-то подсказка'
      },
      {
        value: '',
        label: 'Имя',
        placeholder: 'Введите ваше имя',
        required: true
      },
      {
        value: '',
        label: 'Отчество',
        placeholder: 'Введите ваше отчество'
      },
      {
        value: '',
        label: 'Очень длинный Label: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam cumque dicta dolor eos est ex fugiat fugit maxime nobis, perferendis perspiciatis possimus ullam vel velit veniam vitae. Nihil, ullam.',
        placeholder: 'Очень длинный placeholder: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam cumque dicta dolor eos est ex fugiat fugit maxime nobis, perferendis perspiciatis possimus ullam vel velit veniam vitae. Nihil, ullam.',
        help: 'Очень длинная подсказка: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam cumque dicta dolor eos est ex fugiat fugit maxime nobis, perferendis perspiciatis possimus ullam vel velit veniam vitae. Nihil, ullam.'
      }
    ],
    inputAutogrow: {
      value: '',
      placeholder: 'а тут что?'
    },
    variantChebur: [
      { id: 1, text: 'Условия труда (рабочее место, общественные помещения)' },
      { id: 2, text: 'Содержание работы (соответствие моим знаниям, навыкам и интересам' },
      { id: 3, text: 'Качество взаимодействия между подразделениями' },
      { id: 4, text: 'Постановка задач и обратная связь от непосредственного руководителя' },
      { id: 5, text: 'Работа с инициативами и идеями сотрудников' },
      {
        id: 6,
        text: 'Карьерные возможности (повышение разряда, участие в программах кадрового резерва, лидерство в изменениях, экспертная карьера, волонтерство и др.)'
      },
      { id: 7, text: 'Объем полномочий для решения поставленных задач' },
      {
        id: 8,
        text: 'Признание достижений сотрудников (например, похвала, одобрение, благодарность, размещение на доске почета и т.д.)'
      },
      { id: 9, text: 'Система постановки целей в компании' }
    ],
    processingInputRadioDivider: {
      id: 1,
      uuid: '',
      code: 'question_1',
      type: 'radio',
      subtype: 'media',
      shuffle: true,
      hardness: 0,
      time_limit: 30,
      text: 'Найдите последовательность, в которой расположены фигуры. Выберите фигуру из списка ниже, которая продолжит данную последовательность.<br>',
      error_text: '',
      media: 'https://lk.demo.linkis.ecopsy.com/uploads/2022/05/24/628caa09e29cb_fd.png',
      variant: [
        {
          linkis_id: 0,
          id: '4',
          text: '',
          media: 'https://lk.demo.linkis.ecopsy.com/uploads/2022/05/24/628caf89ee01a_fd_4.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null,
          file: null
        },
        {
          linkis_id: 0,
          id: '3',
          text: ' ',
          media: 'https://lk.demo.linkis.ecopsy.com/uploads/2022/05/24/628caf8653ff0_fd_3.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null,
          file: null
        },
        {
          linkis_id: 0,
          id: '2',
          text: '',
          media: 'https://lk.demo.linkis.ecopsy.com/uploads/2022/05/24/628caf82b5693_fd_2.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null,
          file: null
        },
        {
          linkis_id: 0,
          id: '1',
          text: ' ',
          media: 'https://lk.demo.linkis.ecopsy.com/uploads/2022/05/24/628caf7e6bc8a_fd_1.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null,
          file: null
        },
        {
          linkis_id: 0,
          id: '5',
          text: ' ',
          media: 'https://lk.demo.linkis.ecopsy.com/uploads/2022/05/24/628caf8db6434_fd_5.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null,
          file: null
        }
      ],
      required: true,
      inactive: false,
      min_val: 0,
      max_val: 0,
      step: 0,
      placeholder: '',
      default: '',
      validate_str: '',
      select_image: false,
      rank_variant: [],
      l_min: 0,
      l_max: 0,
      r_min: 0,
      r_max: 0,
      l_text: '',
      r_text: '',
      m_text: '',
      lists: null,
      trigger: '',
      target: '',
      target_id: '',
      show: 1653893102501551900,
      translations: null,
      correct_answer: '',
      file: null,
      attributes: '{}'
    },
    processingInputCheckboxDivider: {
      id: 1,
      code: 'question_1',
      type: 'radio',
      subtype: 'media',
      text: 'Во сколько раз суммарный валовой сбор зерновых культур США и Канады был меньше суммарного валового сбора России и Франции в 2017 году? Ответ округлите <i>до десятых.</i><br>',
      media: 'switch_light/demo2.png',
      variant: [
        {
          id: '5',
          text: 'Прикладная математика и Юриспруденция',
          media: '',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null
        },
        {
          id: '3',
          text: ' в 1,3 раза',
          media: 'switch_light/demo2.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null
        },
        {
          id: '1',
          text: ' в 1,1 раз',
          media: 'switch_light/demo2.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null
        },
        {
          id: '4',
          text: ' в 1,4 раза',
          media: 'switch_light/demo2.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null
        },
        {
          id: '2',
          text: ' в 1,2 раза',
          media: 'switch_light/demo2.png',
          group: '',
          sort: '99999',
          is_correct: false,
          attribute: '',
          translations: null
        }
      ],
      required: true,
      min_val: 0,
      max_val: 0,
      step: 0
    }
  }),

  methods: {
    ...mapActions([
      'modal/showModal',
      'snackbar/showSnackbar'
    ]),
    showModalHandler () {
      this['modal/showModal']({
        component: {
          name: 'MBase',
          props: {
            title: 'Test',
            subject: 'Проверка работы модального окна через vuex',
            submitBtnText: 'Работает хорошо'
          },
          emits: {
            submit: () => console.log('Сработало!')
          }
        }
      })
    },
    showSnackbarHandler (content, options = {}) {
      this['snackbar/showSnackbar']({ content, options })
    }
  }
}
</script>
